<template>
  <b-modal title="환불처리" ref="modal-refund-status" :hide-footer="true">
    <b-table-simple>
      <tbody>
        <tr>
          <th>회원이름</th>
          <td>{{ modal_refund.user_name }}</td>
        </tr>
        <tr>
          <th>회원Email</th>
          <td>{{ modal_refund.email }}</td>
        </tr>
        <tr>
          <th>환불 상품</th>
          <td>{{ modal_refund.title }}</td>
        </tr>
        <tr>
          <th>결제수단</th>
          <td>{{ modal_refund.ord_way_name }}</td>
        </tr>
        <tr>
          <th>결제금액</th>
          <td>{{ modal_refund.price }}</td>
        </tr>
        <tr>
          <th>사용금액</th>
          <td>{{ modal_refund.use_price }}</td>
        </tr>
        <tr>
          <th>환불요청이유</th>
          <td>{{ modal_refund.issue_name }}</td>
        </tr>
        <tr>
          <th>환불상세사유</th>
          <td>
            <b-form-textarea
              id="textarea"
              v-model="modal_refund.user_memo"
              rows="3"
              max-rows="6"
              readonly
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <th>환불상태 변경</th>
          <td><b-form-select v-model="selectRefundStatus" :options="refundStatusOptions" /></td>
        </tr>
        <tr>
          <th>환불금액</th>
          <td>
            <b-form-input
              v-model="modal_refund.refund_price"
              :readonly="
                selectRefundStatus == '' || selectRefundStatus == '01' || selectRefundStatus == '02'
              "
            />
          </td>
        </tr>
        <tr>
          <th>운영자 메모</th>
          <td>
            <b-form-textarea
              v-model="modal_refund.admin_memo"
              rows="3"
              max-rows="5"
            ></b-form-textarea>
            <!-- <template v-if="selectRefundStatus == '02'">
              <b-form-textarea v-model="admin_memo" rows="3" max-rows="5"></b-form-textarea>
            </template>
            <template v-else>
              <b-form-textarea
                v-model="admin_memo"
                rows="3"
                max-rows="5"
                readonly
              ></b-form-textarea>
            </template> -->
          </td>
        </tr>
      </tbody>
    </b-table-simple>
    <b-row>
      <b-col class="d-flex flex-row-reverse">
        <b-button @click="close"> 취소</b-button>
        <b-button variant="danger" @click="save" class="btn btn-danger mr-2">적용</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      selectRefundStatus: ''
    }
  },
  props: ['modal_refund', 'refundStatusOptions'],
  watch: {
    modal_refund() {
      this.selectRefundStatus = this.modal_refund.rf_stat_cd
    },
    refundStatusOptions() {
      console.log('2', this.refundStatusOptions)
    }
  },
  methods: {
    close() {
      this.$refs['modal-refund-status'].hide()
    },
    async save() {
      console.log(this.$props.modal_refund)
      var rf_sn = this.$props.modal_refund.rf_sn
      var rf_stat_cd = this.selectRefundStatus
      var admin_memo = this.$props.modal_refund.admin_memo
      var price = this.$props.modal_refund.refund_price
      if (rf_stat_cd == '' || rf_stat_cd == '01') {
        alert('처리상태를 선택하세요')
        return
      }
      this.$parent.updateRefundStatus(rf_sn, rf_stat_cd, admin_memo, price)

      this.admin_memo = null
      this.selectRefundStatus = null
    }
  }
}
</script>

<style></style>
