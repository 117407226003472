import { axiosPost, axiosDataObject, axiosPostToUrl, axiosGet } from './config'

/**
 * 결제현황
 * @param {*} curPage
 * @param {*} rowPerPage
 * @param {*} searchType
 * @param {*} searchText
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const getPaymentHistory = (
  curPage = 1,
  rowPerPage = 20,
  searchType = '',
  searchText = '',
  startDate = '',
  endDate = '',
  ordwayCd = '',
  pr_sn = ''
) => {
  //http://{{adm}}/adm_api/payment?curPage=1&rowPerPage=20&searchType=&searchText=&startDate=&endDate=

  // if(startDate)
  // startDate = startDate.replace(/-/gi, '')

  // if (endDate)
  //         endDate = endDate.replace(/-/gi, '')

  var url = `payment?curPage=${curPage}&rowPerPage=${rowPerPage}&searchType=${searchType}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`

  if (ordwayCd) url += `&ordWayCd=${ordwayCd}`
  if (pr_sn) url += `&pr_sn=${pr_sn}`

  console.log(url)
  return axiosGet(url)
}

/**
 *
 * @param {} day 202105
 * @returns
 */
export const getPaymentHistoryPerDay = (curPage = 1, rowPerPage = 20, startDate, endDate) => {
  // http://{{adm}}/adm_api/payment/day/202104
  const url = `payment/day?startDate=${startDate}&endDate=${endDate}&curPage=${curPage}&rowPerPage=${rowPerPage}`
  return axiosGet(url)
}
/**
 *
 * @param {} day 202105
 * @returns
 */
export const getPaymentHistoryPerMonth = (curPage = 1, rowPerPage = 20, startDate, endDate) => {
  // http://{{adm}}/adm_api/payment/day/202104
  const url = `payment/month?startDate=${startDate}&endDate=${endDate}&curPage=${curPage}&rowPerPage=${rowPerPage}`
  return axiosGet(url)
}

/**
 * 환불현황
 * @param {*} curPage
 * @param {*} rowPerPage
 * @param {*} searchType
 * @param {*} searchText
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const getRefundList = (
  curPage = 1,
  rowPerPage = 20,
  searchType = '',
  searchText = '',
  startDate = '',
  endDate = '',
  ordWayCd = ''
) => {
  //http://{{adm}}/adm_api/payment?curPage=1&rowPerPage=20&searchType=&searchText=&startDate=&endDate=
  var url = `payment/refund?=curPage=${curPage}&rowPerPage=${rowPerPage}&startDate=${startDate}&endDate=${endDate}`
  if (searchText) url += `&searchText=${searchText}`
  if (ordWayCd) url += `&ordWayCd=${ordWayCd}`
  if (searchType) url += `&searchType=${searchType}`
  return axiosGet(url)
}

/**
 * 환불 상세정보
 * @param {*} rf_sn 
 * @returns 
 * 
 * {
    "code": "0000",
    "message": "",
    "data": {
        "issue_cd": "02",
        "user_name": "애플2",
        "admin_memo": "환불해줌",
        "title": "All in One 구독권 30",
        "use_price": null,
        "refund_price": 200,
        "rf_sn": 10,
        "issue_name": "결제 오류 및 재결제",
        "user_sn": 1000038,
        "rf_stat_cd": "03",
        "price": 19900,
        "user_memo": "1000038",
        "admin_sn": 1,
        "ord_way_cd": "01",
        "admin_name": "Jace",
        "ord_way_name": "계좌이체",
        "email": "snstest02@innosalt.com"
    }
}
 */
export const getRefundDetail = rf_sn => {
  if (!rf_sn) return
  var url = `payment/refund/${rf_sn}`
  return axiosGet(url)
}

/**
 * 환불상태 업데이트
 * @param {*} rf_sn
 * @param {*} rf_stat_cd
 * @param {*} admin_memo
 * @param {*} price
 * @returns
 */
export const updateRefund = (rf_sn, rf_stat_cd, admin_memo, price = 0) => {
  console.log(rf_sn, rf_stat_cd, admin_memo, price)
  if (rf_sn && rf_stat_cd && admin_memo) {
    var url = `payment/refund/`
    price = price.replace(/,/gi, '')
    return axiosPostToUrl(url, { rf_sn, rf_stat_cd, admin_memo, price })
  } else {
    alert(`데이터 입력하세요`)
  }
}
